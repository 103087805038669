import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

export const menuItems = [
    { name: 'Home', path: '/' },
    { name: 'Courses', path: '/courses' },
    { name: 'DailyRoutine', path: '/daily-routine' }
    // { name: 'PortfolioStore', path: '/portfolio-store' }
    // { name: 'About', path: '/about' },
    // { name: 'Contact', path: '/contact' }
];
const PageLinks = () => {
    return (
        <ul className="hidden lg:flex md:flex space-x-4">
            {menuItems.map((item) => (
                <motion.li
                    key={item.name}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <Link to={item.path} className="hover:text-secondary">
                        {item.name}
                    </Link>
                </motion.li>
            ))}
        </ul>
    );
};

export default PageLinks;
