import React, { useContext, useEffect, useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { ThemeContext } from '../context/ThemeContext';
import TypewriterText from '../components/styled/TypewriterText';
import { loadSlim } from "@tsparticles/slim";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import Lottie from 'react-lottie-player';  // Import Lottie Player
import animationData from '../assets/heart-animation.json';  // Import your Lottie animation
import spinnerAnimationLottie from '../assets/background-particles.json';

// Dynamically import your 3D Model

const fadeIn = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 1 } },
};

const Home = () => {
    const [init, setInit] = useState(false);
    const { theme, themes, particleOption, particleOptions } = useContext(ThemeContext);

    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSlim(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const particlesLoaded = (container) => {
        console.log(container);
    };

    const homeParticleOptions = useMemo(() => {
        if (!themes || !theme || !themes[theme]) return {};
        return particleOptions[particleOption];
    }, [themes, theme, particleOptions, particleOption]);

    return (
        <>
            <Helmet>
                <title>Codesios, Shivam Patel IOS</title>
                <meta name="description" content="Codesios" />
            </Helmet>

            {/* Particles Background */}
            {init && (
                <Particles
                    id="tsparticles"
                    particlesLoaded={particlesLoaded}
                    options={homeParticleOptions}
                />
            )}

            {/* Main Section */}
            <section
                className={`flex flex-col justify-start items-center mb-5 p-0 mt-9 text-center ${themes[theme].text}`}
                style={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position: 'relative',
                }}
            >

                <TypewriterText text="Codesios" />
                {/* Lottie Animation */}
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 * 1 }} // Delay based on number of socials
                >
                    <Lottie
                        loop
                        animationData={animationData}
                        play
                        style={{ width: 150, height: 150, animation: 'spin 1s linear infinite' }}  // Set the size to 40x40px
                    />
                </motion.div>
                <style>
                    {`
                        @keyframes spin {
                            from {
                            transform: rotate(0deg);
                            }
                            to {
                            transform: rotate(360deg);
                            }
                        }
                    `}
                </style>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 2 * 1 }} // Delay based on number of socials
                >
                    <motion.p
                        className={`text-2xl md:text-3xl ${themes[theme].subtext}`}
                        variants={fadeIn}
                        initial="initial"
                        animate="animate"
                        style={{
                            color: '#ffffff',
                            fontFamily: 'Arial',
                            fontWeight: 600,  // No quotes for numeric values
                            textAlign: 'center',
                            marginBottom: '30px',
                            textShadow: '0px 5px 10px #8b004b' // Shorthand for text shadow
                        }}
                    >
                        Coming Soon
                    </motion.p>
                </motion.div>
                <motion.p
                    className={`w-2/3 text-xl mt-2 md:text-xl mb-0 ${themes[theme].subtext}`}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 3 * 1 }} // Delay based on number of socials
                >
                    Learning DSA made easy, Stay tuned for swift-dsa courses, insightful posts, and cutting-edge learning materials
                    with love curated series on basics of programming languages!
                </motion.p>
                {/* Lottie Animation */}
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 4 * 1 }} // Delay based on number of socials
                >
                    <Lottie
                        loop
                        animationData={spinnerAnimationLottie}
                        play
                        style={{ width: 250, height: 250 }}  // Set the size to 40x40px
                    />
                </motion.div>
            </section>
        </>
    );
};

export default Home;
