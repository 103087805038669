import React, { createContext, useState, useEffect } from 'react';
import { particleOptions } from './particleOptions.js';
import { themes } from './themes'

// Create the ThemeContext
export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    // Initialize the theme with the value from localStorage or fallback to 'light'
    const [theme, setTheme] = useState(() => {
        return localStorage.getItem('theme') || 'codesios';
    });
    const [particleOption, setParticleOption] = useState(() => {
        return localStorage.getItem('particleOption') || 'codesios';
    });

    // Whenever the theme changes, update the value in localStorage
    useEffect(() => {
        localStorage.setItem('theme', theme);
        localStorage.setItem('particleOption', particleOption);
    }, [theme, particleOption]);

    // Function to change the theme
    const changeTheme = (newTheme, newParticle) => {
        if (newTheme != null) {
            setTheme(newTheme);
        }
        if (newParticle != null) {
            setParticleOption(newParticle);
        }
    };

    return (
        <ThemeContext.Provider value={{ theme, particleOption, changeTheme, themes, particleOptions }}>
            {children}
        </ThemeContext.Provider>
    );
};
