import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { menuItems } from './PageLinks';

const SideMenu = ({ menuOpen, setMenuOpen, themes, changeTheme, particleOptions }) => {
    return (
        <>
            {menuOpen && (
                <motion.div
                    initial={{ x: '100%' }}
                    animate={{ x: 0 }}
                    transition={{ type: 'spring', stiffness: 100 }}
                    className={`mt-[4.45rem] pt-1 fixed inset-0 z-20 bg-black bg-opacity-85 text-white p-6 lg:hidden md:hidden overflow-auto`}
                >
                    <ul className="space-y-4 mt-3 text-white">
                        {menuItems.map((item) => (
                            <li key={item.name}>
                                <Link
                                    to={item.path}
                                    onClick={() => setMenuOpen(false)} // Close menu on link click
                                    className="block text-xl hover:text-secondary"
                                >
                                    {item.name}
                                </Link>
                            </li>
                        ))}
                    </ul>

                    {/* Theme Switcher in the side menu */}
                    {/* <div className="mt-9">
                        <h4 className="text-lg font-bold mb-2">Change Theme</h4>
                        <ul>
                            {Object.keys(themes).map((themeName) => (
                                <li
                                    key={themeName}
                                    onClick={() => {
                                        changeTheme(themeName, null);
                                        setMenuOpen(false); // Close menu after theme change
                                    }}
                                    className="block px-4 py-2 hover:text-secondary cursor-pointer"
                                >
                                    {themeName.charAt(0).toUpperCase() + themeName.slice(1)}
                                </li>
                            ))}
                        </ul>
                    </div> */}

                    {/* Particle Switcher in the side menu */}
                    {/* <div className="mt-2">
                        <h4 className="text-lg font-bold mb-2">Particles</h4>
                        <ul>
                            {Object.keys(particleOptions).map((optionName) => (
                                <li
                                    key={optionName}
                                    onClick={() => {
                                        changeTheme(null, optionName);
                                        setMenuOpen(false); // Close menu after theme change
                                    }}
                                    className="block px-4 py-2 hover:text-secondary cursor-pointer"
                                >
                                    {optionName.charAt(0).toUpperCase() + optionName.slice(1)}
                                </li>
                            ))}
                        </ul>
                    </div> */}
                </motion.div>
            )}
        </>
    );
};

export default SideMenu;
