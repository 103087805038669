import React, { useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext';

const NotFound = () => {
    const { theme, themes } = useContext(ThemeContext);

    return (
        <div className={`${themes[theme].background} ${themes[theme].text} mt-9 pt-9 flex flex-col items-center justify-center`}>
            <h1 className="text-4xl font-bold">Page Not Found</h1>
            <p className="text-lg mt-4">Oops! The page you're looking for doesn't exist.</p>
        </div>
    );
};

export default NotFound;
