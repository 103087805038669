export const defaultRoutine = [
    {
        time: '4:30 AM - 4:40 AM - Wake Up',
        details: 'Immediately upon waking, drink a glass of water to hydrate. Quick Physical Warm-Up (10 minutes): Light stretching or bodyweight exercises to wake up your body.',
    },
    {
        time: '4:40 AM - 5:00 AM - Morning Mindset',
        details: 'Mental Preparation (20 minutes): Spend a few minutes meditating or practicing deep breathing to clear your mind and set your intention for the day.',
    },
    {
        time: '5:00 AM - 7:00 AM - Deep Work Session 1',
        details: 'Focus on Learning & Skill Development (2 hours): Start your day with the most challenging learning tasks. This could be mastering new iOS frameworks, diving into advanced algorithms, or working on complex coding problems.',
    },
    {
        time: '7:00 AM - 7:15 AM - Quick Break',
        details: 'Mental Reset (15 minutes): Take a short break to reset your focus. Stretch, walk around, or do a quick breathing exercise.',
    },
    {
        time: '7:15 AM - 10:15 AM - Deep Work Session 2',
        details: 'Coding & Project Development (3 hours): Focus on building your projects or contributing to real-world applications. Apply what you’ve learned in the morning session.',
    },
    {
        time: '10:15 AM - 10:30 AM - Physical Health Maintenance',
        details: 'Exercise (15 minutes): Quick and intense physical exercise session (e.g., push-ups, squats, planks). This keeps your body energized and supports mental clarity.',
    },
    {
        time: '10:30 AM - 11:00 AM - Quick Lunch',
        details: 'Healthy, Nutrient-Dense Meal (30 minutes): Eat a balanced meal that fuels your brain and body. Keep it light and nutritious.',
    },
    {
        time: '11:00 AM - 2:00 PM - Deep Work Session 3',
        details: 'Problem-Solving & Algorithm Practice (3 hours): Dedicate this time to practicing coding problems, especially focusing on areas you find challenging. Use platforms like LeetCode or HackerRank.',
    },
    {
        time: '2:00 PM - 2:15 PM - Short Break',
        details: 'Mental Reset (15 minutes): Another quick break to stretch and clear your mind.',
    },
    {
        time: '2:15 PM - 5:15 PM - Deep Work Session 4',
        details: 'Advanced iOS Development (3 hours): Work on advanced topics like app architecture, optimization, or learning about new Apple technologies. This is your time to push boundaries.',
    },
    {
        time: '5:15 PM - 5:30 PM - Quick Physical Activity',
        details: 'Short Workout (15 minutes): Another brief but intense exercise session to keep your energy levels up.',
    },
    {
        time: '5:30 PM - 6:00 PM - Dinner',
        details: 'Quick, Healthy Dinner (30 minutes): Another balanced meal to sustain energy levels for the evening session.',
    },
    {
        time: '6:00 PM - 9:00 PM - Deep Work Session 5',
        details: 'Project Work or Learning (3 hours): Continue working on your projects or learning. If you’re working on a project, focus on implementing complex features or optimizing code.',
    },
    {
        time: '9:00 PM - 9:30 PM - Review & Plan',
        details: 'Daily Review (30 minutes): Reflect on what you’ve achieved during the day. Plan and prioritize tasks for the next day. This helps maintain a clear focus on your goals.',
    },
    {
        time: '9:30 PM - 10:00 PM - Wind Down',
        details: 'Mental Relaxation (30 minutes): Wind down with light stretching or meditation. This will help you transition to sleep while maintaining a focused mind.',
    },
    {
        time: '10:00 PM - 4:30 AM - Sleep',
        details: 'Sleep (6.5 hours): Aim for at least 6.5 hours of sleep to ensure your brain and body are well-rested and ready for the next day’s intense routine.',
    },
];