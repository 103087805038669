import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const TypewriterText = ({ text }) => {
    const [displayedText, setDisplayedText] = useState('€');
    const [isDeleting, setIsDeleting] = useState(false);

    const speed = 100; // Increased typing speed

    useEffect(() => {
        const handleTyping = () => {
            const fullText = text;
            const currentText = isDeleting
                ? fullText.substring(0, displayedText.length - 1)
                : fullText.substring(0, displayedText.length + 1);

            setDisplayedText(currentText);

            if (!isDeleting && currentText === fullText) {
                setTimeout(() => setIsDeleting(true), 1000); // Pause before deleting
            } else if (isDeleting && currentText === '') {
                setTimeout(() => setIsDeleting(false), 500); // Pause before starting typing again
                setDisplayedText('€'); // Keep a space when text is empty
            }
        };

        const timer = setTimeout(handleTyping, speed);

        return () => clearTimeout(timer);
    }, [displayedText, isDeleting, text]);

    return (
        <motion.h6
            className="text-l font-extrabold mb-4 tracking-widest text-3xl lg:text-5xl md:text-5xl"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            style={{
                color: '#ffffff',
                fontFamily: 'Arial',
                fontWeight: 'bold',
                textAlign: 'center',
                marginTop: 0,
                textShadow: '0px 5px 10px #00f0ff', // Correct way to set text shadow in CSS
            }}
        >
            {displayedText}
        </motion.h6>
    );
};

export default TypewriterText;
