import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { defaultRoutine } from '../data/daily-routines';
import {
    IoSaveOutline,
    IoCloseOutline,
    IoPencilOutline,
    IoDownloadOutline,
    IoCloudUploadOutline,
    IoRefreshOutline,
    IoAddOutline,
    IoArrowBackOutline,
} from 'react-icons/io5';

const DailyRoutinePage = () => {
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768); // Screen width breakpoint for md/lg
    // Similar to React Native logic, handle screen resize
    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const getCurrentTimeBlock = () => {
        const now = new Date();
        const currentHour = now.getHours();
        const currentMinutes = now.getMinutes();

        for (let i = 0; i < routine.length; i++) {
            const time = routine[i]?.time;
            if (time && typeof time === 'string') {
                const timeRange = extractTimeRange(time);
                if (timeRange.length === 2) {
                    const [startHour, startMinutes] = parseTime(timeRange[0]);
                    const [endHour, endMinutes] = parseTime(timeRange[1]);

                    if (
                        (currentHour > startHour ||
                            (currentHour === startHour && currentMinutes >= startMinutes)) &&
                        (currentHour < endHour ||
                            (currentHour === endHour && currentMinutes < endMinutes))
                    ) {
                        return routine[i].time;
                    }
                }
            }
        }
        return null; // No matching time block found
    };

    const extractTimeRange = (timeString) => {
        const parts = timeString.split(' - ');
        return [parts[0], parts[1]]; // Extract only the start and end times
    };

    const parseTime = (timeString) => {
        const [hourString, minuteString] = timeString.match(/\d+/g) || [];
        let hour = parseInt(hourString, 10);
        const minute = parseInt(minuteString || '0', 10);
        const isPM = timeString.includes('PM');

        if (isPM && hour !== 12) hour += 12;
        if (!isPM && hour === 12) hour = 0;

        return [hour, minute];
    };

    const importRoutineFromExcel = async () => {
        try {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = '.xlsx, .xls';
            input.onchange = (e) => {
                const file = e.target.files[0];
                if (file) {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        const fileContent = event.target.result;
                        processExcelFile(fileContent);
                    };
                    reader.readAsArrayBuffer(file);
                }
            };
            input.click();
        } catch (error) {
            console.error('Error importing routine:', error);
        }
    };

    const processExcelFile = (fileContent) => {
        try {
            const workbook = XLSX.read(fileContent, { type: 'array' });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];

            if (worksheet['!ref']) {
                const range = XLSX.utils.decode_range(worksheet['!ref']);

                if (range.s.r === 0) {
                    range.s.r = 1;
                    worksheet['!ref'] = XLSX.utils.encode_range(range);
                }

                const importedRoutine = XLSX.utils
                    .sheet_to_json(worksheet, { header: 1 })
                    .slice(1)
                    .map((row) => ({
                        time: row[0],
                        details: row[1],
                    }));
                setRoutine(importedRoutine);
            } else {
                console.error('Error: No valid range found in the worksheet.');
            }
        } catch (error) {
            console.error('Error processing Excel file:', error);
        }
    };

    const downloadRoutineAsExcel = async (currentRoutine) => {
        try {
            const websiteName = 'Codesios';

            const worksheet = XLSX.utils.aoa_to_sheet([
                [websiteName, 'Daily Schedule'],
                ['Time', 'Routine'],
            ]);

            XLSX.utils.sheet_add_json(
                worksheet,
                currentRoutine.map((item) => ({
                    Time: item.time,
                    Routine: item.details,
                })),
                { origin: 'A3', skipHeader: true }
            );

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Routine');

            const excelData = XLSX.write(workbook, {
                type: 'array',
                bookType: 'xlsx',
            });
            const blob = new Blob([excelData], {
                type:
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            saveAs(blob, 'codesios_routine.xlsx');
            console.log('File downloaded successfully on web');
        } catch (error) {
            console.error('Error downloading routine:', error);
        }
    };

    const [routine, setRoutine] = useState(() => {
        const savedRoutine = localStorage.getItem('routine');
        return savedRoutine ? JSON.parse(savedRoutine) : defaultRoutine;
    });

    const [editMode, setEditMode] = useState(false);
    const [originalRoutine, setOriginalRoutine] = useState(routine);

    useEffect(() => {
        localStorage.setItem('routine', JSON.stringify(routine));
    }, [routine]);

    const startEdit = () => {
        setOriginalRoutine(routine);
        setEditMode(true);
    };

    const cancelEdit = () => {
        setRoutine(originalRoutine);
        setEditMode(false);
    };

    const resetRoutine = () => {
        setRoutine(defaultRoutine);
        setEditMode(false);
    };

    const updateRoutineItem = (index, key, value) => {
        const updatedRoutine = [...routine];
        updatedRoutine[index][key] = value;
        setRoutine(updatedRoutine);
    };

    const addRoutineItem = () => {
        setRoutine([...routine, { time: '', details: '' }]);
    };

    const removeRoutineItem = (index) => {
        const updatedRoutine = routine.filter((_, i) => i !== index);
        setRoutine(updatedRoutine);
    };

    const currentTimeBlock = getCurrentTimeBlock();

    return (
        <div style={styles.container}>
            {/* Header */}
            <div style={styles.header}>
                <button onClick={() => window.history.back()} style={styles.backButton}>
                    <IoArrowBackOutline size={24} color="#fff" />
                </button>
                <h1 style={styles.headerTitle}>Codesios Daily Routine</h1>

                {/* Header Buttons */}
                <div style={{ display: 'flex', gap: '10px' }}>
                    {isLargeScreen ? (
                        <>
                            {editMode ? (
                                <>
                                    <button onClick={() => setEditMode(false)} style={{ ...styles.button, backgroundColor: '#034b94' }}>
                                        <IoSaveOutline size={16} color="#fff" />
                                        <span style={styles.buttonText}>Save</span>
                                    </button>
                                    <button onClick={cancelEdit} style={{ ...styles.button, backgroundColor: '#941103' }}>
                                        <IoCloseOutline size={16} color="#fff" />
                                        <span style={styles.buttonText}>Cancel</span>
                                    </button>
                                </>
                            ) : (
                                <button onClick={startEdit} style={{ ...styles.button, backgroundColor: '#1F1F1F', borderColor: '#FFD700', borderWidth: '2px', borderStyle: 'solid' }}>
                                    <IoPencilOutline size={16} color="#fff" />
                                    <span style={styles.buttonText}>Edit</span>
                                </button>
                            )}
                            <button onClick={importRoutineFromExcel} style={{ ...styles.button, backgroundColor: '#1F1F1F', borderColor: '#FFD700', borderWidth: '2px', borderStyle: 'solid' }}>
                                <IoDownloadOutline size={16} color="#fff" />
                                <span style={styles.buttonText}>Import</span>
                            </button>
                            <button onClick={() => downloadRoutineAsExcel(routine)} style={{ ...styles.button, backgroundColor: '#1F1F1F', borderColor: '#FFD700', borderWidth: '2px', borderStyle: 'solid' }}>
                                <IoCloudUploadOutline size={16} color="#fff" />
                                <span style={styles.buttonText}>Export</span>
                            </button>
                            <button onClick={resetRoutine} style={{ ...styles.button, backgroundColor: '#1F1F1F', borderColor: '#FFD700', borderWidth: '2px', borderStyle: 'solid' }}>
                                <IoRefreshOutline size={16} color="#fff" />
                                <span style={styles.buttonText}>Reset</span>
                            </button>
                        </>
                    ) : (
                        <>
                            <button style={{ ...styles.button, backgroundColor: '#1F1F1F', borderColor: '#FFD700', borderWidth: '2px', borderStyle: 'solid' }}>
                                <IoPencilOutline size={16} color="#fff" />
                            </button>
                            <button style={{ ...styles.button, backgroundColor: '#1F1F1F', borderColor: '#FFD700', borderWidth: '2px', borderStyle: 'solid' }}>
                                <IoDownloadOutline size={16} color="#fff" />
                            </button>
                            <button style={{ ...styles.button, backgroundColor: '#1F1F1F', borderColor: '#FFD700', borderWidth: '2px', borderStyle: 'solid' }}>
                                <IoCloudUploadOutline size={16} color="#fff" />
                            </button>
                            <button style={{ ...styles.button, backgroundColor: '#1F1F1F', borderColor: '#FFD700', borderWidth: '2px', borderStyle: 'solid' }}>
                                <IoRefreshOutline size={16} color="#fff" />
                            </button>
                        </>
                    )}
                </div>
            </div>
            <div style={styles.subContainer}>
                {/* Routine List */}
                <div>
                    {routine.map((item, index) => (
                        <div
                            key={index}
                            style={{
                                ...styles.section,
                                ...(currentTimeBlock === item.time ? styles.currentSection : {}),
                            }}
                        >
                            {editMode ? (
                                <>
                                    <input
                                        style={styles.time}
                                        value={item.time}
                                        onChange={(e) =>
                                            updateRoutineItem(index, 'time', e.target.value)
                                        }
                                    />
                                    <textarea
                                        style={styles.details}
                                        value={item.details}
                                        onChange={(e) =>
                                            updateRoutineItem(index, 'details', e.target.value)
                                        }
                                    />
                                    <button
                                        onClick={() => removeRoutineItem(index)}
                                        style={styles.deleteButton}
                                    >
                                        <IoCloseOutline size={16} color="#fff" />
                                    </button>
                                </>
                            ) : (
                                <>
                                    <p style={styles.time}>{item.time}</p>
                                    <p style={styles.details}>{item.details}</p>
                                </>
                            )}
                        </div>
                    ))}
                    {editMode && (
                        <div style={{ textAlign: 'center', margin: '10px' }}>
                            <button
                                onClick={addRoutineItem}
                                style={{
                                    ...styles.button,
                                    backgroundColor: '#1F1F1F',
                                    borderColor: '#FFD700',
                                    borderWidth: '2px',
                                    borderStyle: 'solid',
                                }}
                            >
                                <IoAddOutline size={16} color="#fff" />
                                <span style={styles.buttonText}>Add Routine</span>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        padding: '0px',
        backgroundColor: '#1c1c1e',
        minHeight: '100vh',
        color: '#e5e5e7',
    },
    subContainer: {
        padding: '20px',
        backgroundColor: '#1c1c1e',
        minHeight: '100vh',
        color: '#e5e5e7',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#000',  // Solid black for the header
        height: '60px',           // Similar to React Native Stack Navigator
        padding: '0 20px',        // Padding on both sides
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',  // Light shadow for stack effect,
        borderBottom: '1px solid #fff',

    },
    backButton: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        marginRight: 'auto',
    },
    headerTitle: {
        fontSize: '22px',
        fontWeight: "bold",
        marginLeft: "20px",
        color: '#fff',
        textAlign: 'left',
        flex: 1,
    },
    section: {
        marginBottom: '15px',
        padding: '15px',
        backgroundColor: '#2c2c2e',
        borderRadius: '12px',
        boxShadow: '0px 3px 5px rgba(0,0,0,0.3)',
        border: '1px solid #444',
        position: 'relative',
    },
    currentSection: {
        backgroundColor: '#8b004b',
    },
    time: {
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '5px',
        color: '#ffd60a',
        backgroundColor: '#333',
        padding: '10px',
        borderRadius: '5px',
        width: '100%',
        border: 'none',
        outline: 'none',
        boxSizing: 'border-box',
    },
    details: {
        fontSize: '16px',
        color: '#d1d1d6',
        backgroundColor: '#333',
        padding: '10px',
        borderRadius: '5px',
        marginBottom: '10px',
        width: '100%',
        border: 'none',
        outline: 'none',
        boxSizing: 'border-box',
        resize: 'vertical',
    },
    deleteButton: {
        position: 'absolute',
        right: '10px',
        top: '10px',
        backgroundColor: '#ff3b30',
        width: '32px',
        height: '32px',
        borderRadius: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        cursor: 'pointer',
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#1e90ff',
        padding: '6px',
        borderRadius: '8px',
        cursor: 'pointer',
        border: 'none',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.3)',
    },
    buttonText: {
        color: '#fff',
        fontSize: '14px',
        fontWeight: '600',
        letterSpacing: '0.5px',
        marginLeft: '8px',
    },
};

export default DailyRoutinePage;
