import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';  // Import useNavigate from React Router

const CodesiosLogo = () => {
    const navigate = useNavigate();  // Initialize the navigate function

    const handleLogoClick = () => {
        navigate('/');  // Navigate to the home page
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className='hover:text-primary cursor-pointer'  // Add cursor pointer for visual feedback
            onClick={handleLogoClick}  // Navigate to home when clicked
        >
            <div className="text-2xl font-bold">
                Codesios
            </div>
        </motion.div>
    );
};

export default CodesiosLogo;
