import React, { useContext } from 'react';
import { ThemeContext } from "../../context/ThemeContext";

const Footer = () => {
    const { theme, themes } = useContext(ThemeContext); // Use theme from context
    return (
        <footer className="p-4 pt-0 mt-0">
            <div className={`${themes[theme].text} container mx-auto text-center`}>
                © {new Date().getFullYear()} Codesios. All rights reserved.
            </div>
        </footer>
    );
};

export default Footer;