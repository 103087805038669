import React from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';

const HamburgerMenu = ({ menuOpen, setMenuOpen }) => {
    return (
        <div className="lg:hidden md:hidden">
            <button
                onClick={() => setMenuOpen(!menuOpen)}
                className="text-3xl focus:outline-none"
            >
                {menuOpen ? <FaTimes /> : <FaBars />} {/* Toggle between bars and close icon */}
            </button>
        </div>
    );
};

export default HamburgerMenu;
