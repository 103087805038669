import React, { useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext';
import { Download } from 'lucide-react';
import { courses } from '../data/courses';

const Courses = () => {
    const { theme, themes } = useContext(ThemeContext);

    const handleDownload = (syllabusLink, title) => {
        const link = document.createElement('a');
        link.href = syllabusLink;
        link.setAttribute('download', `${title.toLowerCase().replace(/\s+/g, '-')}-syllabus.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="pt-3 container mx-auto px-4">
            <h1 className={`text-3xl text-center mb-8 ${themes[theme].text}`}>Courses</h1>

            <div className="flex flex-col items-center gap-6">
                {courses.map((course) => (
                    <div
                        key={course.id}
                        className="dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden transition-transform duration-300 max-w-96 w-full hover:scale-105"
                    >
                        <div className="p-4">
                            <h2 className={`text-xl font-semibold mb-2 ${themes[theme].text}`}>
                                {course.title}
                            </h2>

                            {course.description && (
                                <p className={`text-sm mb-4 ${themes[theme].textSecondary}`}>
                                    {course.description}
                                </p>
                            )}

                            <button
                                onClick={() => handleDownload(course.syllabusLink, course.title)}
                                className={`w-full flex items-center justify-center gap-2 ${themes[theme].buttonBackground} hover:bg-blue-600 text-white py-2 px-4 rounded-md transition-colors duration-300`}
                            >
                                <Download size={16} />
                                Download Syllabus
                            </button>
                        </div>
                        <div className="flex justify-center items-center">
                            <img
                                src={course.image}
                                alt={course.title}
                                className="max-w-full h-auto object-contain"
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div >
    );
};

export default Courses;