// courses.js
export const courses = [
    {
        id: 1,
        title: 'IOS Interview Swift Interview Preperation Mastery',
        description: 'IOS Course',
        image: '/assets/courses/codesios_ios2.png',
        syllabusLink: "https://drive.google.com/file/d/1trFrEtRCXXT9mYEPng-DMA8NuScRnrti/view?usp=sharing"
    }
    // Add more courses as needed
];